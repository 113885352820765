import { Box, Button, CircularProgress, Icon, Typography } from "@mui/material";
import ColoredButton from "app/shared-components/ColoredButton";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { loggingError } from "../PaySlipServices";

const addFile = (e, files) => {
  if (e.target.files.length > 0) {
    files.current = e.target.files[0];
  }
};

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

export default function PaySlipSpread() {
  const paySlipFile = useRef(undefined);
  const [progress, setProgress] = useState(0);
  const [processing, setProcessing] = useState(false);
  const [icon, setIcon] = useState(0);
  const [done, setDone] = useState(false);

  useEffect(() => {
    if (progress >= 100) {
      setIcon(1);
    }
  }, [progress]);

  const submitDecompte = (onUploadProgress) => {
    setProgress(0);
    setProcessing(true);
    const form = new FormData();
    form.append(`file`, paySlipFile.current);
    axios
      .post("api/payslip/spread", form, {
        onUploadProgress,
      })
      .then((res) => {
        setIcon(2);
        setProcessing(false);
        setDone(true);
      })
      .catch((err) => {
        setIcon(3);
        loggingError(JSON.stringify(err.response));
        console.error(err);
      });
  };

  return (
    <>
      <Box className="flex justify-center items-center gap-8">
        <input
          type="file"
          name="FileAttachment"
          id="FileAttachment"
          className="upload"
          onChange={(e) => addFile(e, paySlipFile)}
        />
        <ColoredButton
          callback={() =>
            submitDecompte((event) => {
              setProgress(Math.round((100 * event.loaded) / event.total));
            })
          }
          text="Diffuser"
        />
      </Box>
      <div className="flex flex-row justify-center align items-center">
        <CircularProgressWithLabel color="inherit" value={progress} />
        <span>Chargement du fichier</span>
      </div>
      <div className="flex flex-row justify-center align items-center">
        {icon === 0 && (
          <CircularProgress variant="determinate" size={20} value={75} />
        )}
        {icon === 1 && <CircularProgress size={20} color="inherit" />}
        {icon === 2 && <Icon color="success"> check_box</Icon>}
        {icon === 3 && <Icon style={{ color: "red" }}> cancel</Icon>}
        <span>Traitements des bulletins de paie</span>
      </div>
    </>
  );
}
