import Typography from "@mui/material/Typography";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import {
  downloadRulesOfProcedure,
  getRulesOfPRocedure,
} from "../settings/rules_procedure/RulesOfProcedureServices";
import { Box, Button, IconButton } from "@mui/material";
import fileDownload from "js-file-download";

export default function DashboardHeader(props) {
  const [existingRules, setExistingRules] = useState(false);
  const [loading, setLoading] = useState();

  useEffect(() => {
    setLoading(true);
    getRulesOfPRocedure()
      .then((res) => {
        setExistingRules(res.data);
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  }, []);

  function handleDownload() {
    downloadRulesOfProcedure().then((res) => {
      fileDownload(res.data, `reglement_interieur.pdf`);
    });
  }

  return (
    <div className="p-24 sm:p-32 w-full border-b-1 flex justify-between">
      <div className="flex flex-col items-center sm:items-start">
        <Typography
          component={motion.span}
          initial={{ x: -20 }}
          animate={{ x: 0, transition: { delay: 0.2 } }}
          delay={300}
          className="text-24 md:text-32 font-extrabold tracking-tight leading-none"
        >
          Accueil
        </Typography>
      </div>
      {existingRules ? (
        <Button variant="contained" onClick={handleDownload}>
          <PictureAsPdfIcon sx={{ color: "red", marginRight: "5px" }} />
          <Typography>Télécharger Le règlement intérieur</Typography>
        </Button>
      ) : null}
    </div>
  );
}
