import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Tooltip from "@mui/material/Tooltip";
import { Fragment, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import TableHead from "@mui/material/TableHead";
import {
  Box,
  Button,
  FormControl,
  Icon,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import _ from "@lodash";

const rows = [
  {
    id: "code",
    align: "left",
    disablePadding: false,
    label: "Matricule",
    sort: true,
  },
  {
    id: "last_name",
    align: "left",
    disablePadding: false,
    label: "Nom",
    sort: true,
  },
  {
    id: "first_name",
    align: "left",
    disablePadding: false,
    label: "Prénom",
    sort: true,
  },
  {
    id: "email",
    align: "left",
    disablePadding: false,
    label: "Email",
    sort: true,
  },
  {
    id: "function",
    align: "left",
    disablePadding: false,
    label: "Fonction",
    sort: true,
  },
  {
    id: "department",
    align: "left",
    disablePadding: false,
    label: "Département",
    sort: true,
  },
  {
    id: "action",
    align: "left",
    disablePadding: false,
    label: "Action",
    sort: true,
  },
];

function UserTableHead(props) {
  const {
    selectedProductIds,
    permissions,
    filtre,
    dispatchFiltre,
    submitFilter,
    departments,
  } = props;
  const numSelected = selectedProductIds?.length
    ? selectedProductIds.length
    : 0;

  const [selectedUsersMenu, setSelectedUsersMenu] = useState(null);
  const [selectedFilterMenu, setSelectedFilterMenu] = useState(null);
  const [currentFilter, setCurrentFilter] = useState(null);
  const formRef = useRef({
    code: undefined,
    last_name: undefined,
    first_name: undefined,
    email: undefined,
    department: undefined,
    office: undefined,
  });

  const dispatch = useDispatch();

  const createSortHandler = (property) => (event) => {
    props.onRequestSort(event, property);
  };

  function openSelectedUsersMenu(event) {
    setSelectedUsersMenu(event.currentTarget);
  }

  function closeSelectedUsersMenu() {
    setSelectedUsersMenu(null);
  }
  function openSelectedFilterMenu(event, id) {
    setCurrentFilter(id);
    setSelectedFilterMenu(event.currentTarget);
  }

  function closeSelectedFilterMenu() {
    setSelectedFilterMenu(null);
  }

  function changeClient(e) {
    dispatchFiltre({ type: "client", payload: { value: e.target.value } });
  }

  function applyFilter(field) {
    dispatchFiltre({
      type: `${field}-active`,
      payload: { value: formRef.current[field], active: true },
    });
  }

  const createMenu = (id) => {
    return (
      <Menu
        id="selectedFilterMenu"
        anchorEl={selectedFilterMenu}
        open={Boolean(selectedFilterMenu)}
        onClose={closeSelectedFilterMenu}
        keepMounted={false}
      >
        {currentFilter === "code" && (
          <>
            <div className="m-10 flex flex-col space-y-10">
              <TextField
                label="Code"
                inputRef={(input) => {
                  if (input) {
                    setTimeout(() => {
                      input.focus();
                    }, 10);
                  }
                }}
                ref={formRef}
                defaultValue={filtre.code.code}
                onChange={(e) => {
                  formRef.current.code = e.target.value;
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    applyFilter("code");
                  }
                }}
              />
            </div>
            <div className="flex justify-center">
              <Button
                variant="contained"
                onClick={() => {
                  applyFilter("code");
                }}
              >
                Filter
              </Button>
            </div>
          </>
        )}

        {currentFilter === "last_name" && (
          <>
            <div className="m-10 flex flex-col space-y-10">
              <TextField
                label="Nom"
                inputRef={(input) => {
                  if (input) {
                    setTimeout(() => {
                      input.focus();
                    }, 10);
                  }
                }}
                ref={formRef}
                defaultValue={filtre.last_name.last_name}
                onChange={(e) => {
                  formRef.current.last_name = e.target.value;
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    applyFilter("last_name");
                  }
                }}
              />
            </div>
            <div className="flex justify-center">
              <Button
                variant="contained"
                onClick={() => {
                  applyFilter("last_name");
                }}
              >
                Filter
              </Button>
            </div>
          </>
        )}

        {currentFilter === "first_name" && (
          <>
            <div className="m-10 flex flex-col space-y-10">
              <TextField
                label="Prénom"
                inputRef={(input) => {
                  if (input) {
                    setTimeout(() => {
                      input.focus();
                    }, 10);
                  }
                }}
                ref={formRef}
                defaultValue={filtre.first_name.first_name}
                onChange={(e) => {
                  formRef.current.first_name = e.target.value;
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    applyFilter("first_name");
                  }
                }}
              />
            </div>
            <div className="flex justify-center">
              <Button
                variant="contained"
                onClick={() => {
                  applyFilter("first_name");
                }}
              >
                Filter
              </Button>
            </div>
          </>
        )}

        {currentFilter === "email" && (
          <>
            <div className="m-10 flex flex-col space-y-10">
              <TextField
                label="Email"
                inputRef={(input) => {
                  if (input) {
                    setTimeout(() => {
                      input.focus();
                    }, 10);
                  }
                }}
                ref={formRef}
                defaultValue={filtre.email.email}
                onChange={(e) => {
                  formRef.current.email = e.target.value;
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    applyFilter("email");
                  }
                }}
              />
            </div>
            <div className="flex justify-center">
              <Button
                variant="contained"
                onClick={() => {
                  applyFilter("email");
                }}
              >
                Filter
              </Button>
            </div>
          </>
        )}
        {currentFilter === "department" && (
          <div className="m-10 flex flex-col space-y-10">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Département</InputLabel>
              <Select
                defaultValue={filtre.department.department}
                label="Département"
                onChange={(e) => {
                  formRef.current.department = e.target.value;
                }}
              >
                {departments.map((d) => {
                  return (
                    <MenuItem key={`dep_${d.id}`} value={d.id}>
                      {d.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <Button
              variant="contained"
              onClick={() => {
                applyFilter("department");
              }}
            >
              Filter
            </Button>
          </div>
        )}
        {currentFilter === "office" && (
          <div className="m-10 flex flex-col space-y-10">
            <TextField
              label="Bureau"
              inputRef={(input) => {
                if (input) {
                  setTimeout(() => {
                    input.focus();
                  }, 10);
                }
              }}
              value={filtre.office.office}
              onChange={(e) =>
                dispatchFiltre({
                  type: "office",
                  payload: { value: e.target.value },
                })
              }
            />
            <Button
              variant="contained"
              onClick={() => {
                dispatchFiltre({
                  type: "activeOffice",
                  payload: { value: true },
                });
                submitFilter();
              }}
            >
              Filter
            </Button>
          </div>
        )}
      </Menu>
    );
  };
  return (
    <TableHead>
      <TableRow className="h-48 sm:h-64">
        {rows.map((row, index) => {
          return (
            <Fragment key={`userstablehead_${row.id}_${index}`}>
              {(!row.permissions ||
                _.intersection(permissions, row.permissions).length > 0) && (
                <TableCell
                  style={{
                    position: "sticky",
                    zIndex: 1,
                    minWidth: "auto",
                  }}
                  className="p-4 md:p-16 text-xs"
                  align={row.align}
                  padding={row.disablePadding ? "none" : "normal"}
                  sortDirection={
                    props.order.id === row.id ? props.order.direction : false
                  }
                >
                  {row.sort && (
                    <Tooltip
                      title="Sort"
                      placement={
                        row.align === "right" ? "bottom-end" : "bottom-start"
                      }
                      enterDelay={300}
                    >
                      <TableSortLabel
                        active={props.order.id === row.id}
                        direction={props.order.direction}
                        onClick={createSortHandler(row.id)}
                        className="font-semibold"
                      >
                        {row.label}
                      </TableSortLabel>
                    </Tooltip>
                  )}
                  {row.id !== "action" && (
                    <>
                      <IconButton
                        aria-owns={
                          selectedFilterMenu ? "selectedProductsMenu" : null
                        }
                        aria-haspopup="true"
                        onClick={(event) => {
                          openSelectedFilterMenu(event, row.id);
                        }}
                        size="large"
                      >
                        <Icon>more_horiz</Icon>
                      </IconButton>

                      {selectedFilterMenu && createMenu(row)}
                    </>
                  )}
                </TableCell>
              )}
            </Fragment>
          );
        }, this)}
      </TableRow>
    </TableHead>
  );
}

export default UserTableHead;
