import TrombinoscopePage from "./TrombinoscopePage";

const TrombinoscopeConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: ["canReadTrombinoscope"],
  routes: [
    {
      path: "hr/trombinoscope",
      element: <TrombinoscopePage />,
    },
  ],
};

export default TrombinoscopeConfig;
