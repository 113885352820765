import { useState } from "react";
import FusePageSimple from "@fuse/core/FusePageSimple/FusePageSimple";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import UserTableHead from "./UserTableHead";
import UserTable from "./UserTable";
import HeaderUser from "./UserHeader";

const Root = styled(FusePageSimple)(({ theme }) => ({
  "& .FusePageSimple-header": {
    backgroundColor: theme.palette.background.paper,
    borderBottomWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.divider,
  },
  "& .FusePageSimple-toolbar": {},
  "& .FusePageSimple-content": {},
  "& .FusePageSimple-sidebarHeader": {},
  "& .FusePageSimple-sidebarContent": {},
}));

export default function UserPage() {
  const [openDialog, setOpenDialog] = useState(false);
  const [currentContent, setCurrentContent] = useState("newUsers");
  const [activeUsers, setActiveUsers] = useState(true);
  return (
    <Root
      header={<HeaderUser />}
      content={
        <div className="p-24">
          <br />
          <UserTable
            openDialog={openDialog}
            setOpenDialog={setOpenDialog}
            currentContent={currentContent}
            setCurrentContent={setCurrentContent}
            activeUsers={activeUsers}
          />
        </div>
      }
    />
  );
}
