import axios from "axios";

export function searchPaySlips() {}

export function downloadPaySlip(userId, month, year) {
  const form = new FormData();
  form.append(`user`, userId);
  form.append(`month`, month);
  form.append(`year`, year);

  return axios.post("api/payslip/download", form, {
    responseType: "blob",
  });
}

export function downloadMyPaySlip(month, year) {
  const form = new FormData();
  form.append(`month`, month);
  form.append(`year`, year);

  return axios.post("api/payslip/mypayslips/download", form, {
    responseType: "blob",
  });
}

export function deletePaySlips(slips) {
  return axios.delete("api/payslip/delete", {
    params: {
      slips: btoa(slips.join(",")),
    },
  });
}

export function searchPaySlipsLog(params) {
  return axios.get("api/payslip/searchLogs", {
    params: {
      date: params.date,
      state: params.state,
      page: params.page,
    },
  });
}

export function downloadPaySlipsLogs({ date, state }) {
  const form = new FormData();
  if (date) {
    form.append(`date`, date);
  }
  if (state) {
    form.append(`state`, state);
  }

  return axios.post("api/payslip/logs/download", form, {
    responseType: "blob",
  });
}

export function loggingError(message) {
  return axios.post("api/logging", { message });
}
