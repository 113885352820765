import { styled } from "@mui/material/styles";
import { selectUser } from "app/store/userSlice";
import { useSelector } from "react-redux";

const Root = styled("div")(({ theme }) => ({
  "& > .logo-icon": {
    transition: theme.transitions.create(["width", "height"], {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
  },
  "& > .badge": {
    transition: theme.transitions.create("opacity", {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
  },
}));

function Logo() {
  const user = useSelector(selectUser);
  return (
    <Root className="flex items-center">
      <img
        className="logo-icon w-128"
        src={`assets/images/logo/${user.get_company.id}.png`}
        alt="logo"
      />
    </Root>
  );
}

export default Logo;
