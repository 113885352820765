import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import { useEffect, useRef, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import { editTermsOfUse, updateTermsOfUse } from "./TermsOfUseServices";
import ColoredButton from "app/shared-components/ColoredButton";

export default function TermsOfUseBody() {
  const [loading, setLoading] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [activePolicy, setActivePolicy] = useState(false);
  const editorRef = useRef();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("sm"));

  useEffect(() => {
    editTermsOfUse()
      .then((res) => {
        const contentBlock = htmlToDraft(res.data.text);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks
          );
          setEditorState(EditorState.createWithContent(contentState));
          setActivePolicy(res.data.active);
        }
      })
      .catch((err) => {
        console.log(err);
        alert(
          "Une erreur s'est produite lors de la récupération de la charte de confidentialité"
        );
      });
  }, []);

  function submitPrivacyPolicy() {
    const form = new FormData();
    if (activePolicy) {
      form.append("active", activePolicy);
    }
    form.append(
      "content",
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );
    updateTermsOfUse(form)
      .then((res) => {
        alert("ok");
      })
      .catch((err) => {
        alert(
          "Une erreur s'est produite lors de la MAJ de la charte de confidentialité"
        );
      });
  }

  function onEditorStateChange(state) {
    setEditorState(state);
    // console.log(draftToHtml(convertToRaw(state.getCurrentContent())));
  }

  return (
    <Box className="flex flex-col">
      <Box
        className="rounded-4 border-1 overflow-auto w-full bg-white m-12 w-[95%] h-[50vh]"
        ref={editorRef}
      >
        <Editor
          editorState={editorState}
          editorStyle={{
            padding: "40px", // Add padding for better readability
            paddingTop: isLargeScreen ? "40px" : "100px",
            width: "100%",
          }}
          wrapperStyle={{
            width: "100%", // Set the fixed width for the entire editor container
            margin: "0 auto", // Center the editor horizontally, if needed
          }}
          toolbarStyle={{ position: "absolute", top: "0", zIndex: "9999" }}
          onEditorStateChange={onEditorStateChange}
        />
      </Box>
      <Box>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={activePolicy}
                onChange={(e) => setActivePolicy(e.target.checked)}
              />
            }
            label="Activer Les conditions d'utilisation"
          />
        </FormGroup>
      </Box>
      <ColoredButton
        callback={submitPrivacyPolicy}
        text="Valider"
        sx={{ maxWidth: "250px", margin: "1rem" }}
      />
    </Box>
  );
}
