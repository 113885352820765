import { lazy } from "react";
import authRoles from "../../auth/authRoles";

const ForgotPassword = lazy(() => import("./ForgotPassword"));
const ChangePassword = lazy(() => import("./ChangePassword"));

const ForgotPasswordConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  auth: authRoles.onlyGuest,
  routes: [
    {
      path: "forgot-password",
      element: <ForgotPassword />,
    },
    {
      path: "change-password",
      element: <ChangePassword />,
    },
  ],
};

export default ForgotPasswordConfig;
